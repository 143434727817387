import React, { useState } from 'react';
import './css/faq.css';
import { Link } from 'react-router-dom';

const Faq = () => {
    const [activeQuestion, setActiveQuestion] = useState(null);
    const toggleAccordion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };

    return (
        <>
            <section className="bg-all">
                <div className="container mb-3">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="text-center text-black mb-5">Frequently Asked Questions (FAQ)</h2>
                            <div className="wrapper">
                                {faqData?.map((item, index) => (
                                    <div className="container" key={index} id='faq'>
                                        <div className={`question ${activeQuestion === index ? 'active' : ''}`} id='liststyle' onClick={() => toggleAccordion(index)}>
                                            {item?.question}
                                        </div>
                                        <div className="answercont" style={{ maxHeight: activeQuestion === index ? '1000px' : '0' }}>
                                            <div className="answer">
                                                <img src='assets/img/cristo/tick.png' alt="nodata" className="img-fluid" style={{ width: "20px", height: "20px", marginRight: "10px" }} />{item?.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img
                                src="assets/img/cristo/faq.png"
                                alt="nodata"
                                className="img-fluid"
                            />
                            <h6 className="text-center">
                                For more questions, visit our <Link to="/faq"><b>FAQ page</b></Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faq;

const faqData = [
    {
        question: "What is CristO, and how can it benefit our church?",
        answer: "CristO (ChMS) is software tailored for churches and religious entities to simplify and coordinate diverse administrative responsibilities.",
    },
    {
        question: "Is CristO compatible with our existing church management software?",
        answer: "CristO is compatible with most existing church management software systems. However, compatibility may vary depending on the specific features and integrations required. It's advisable to check with the CristO team or consult the documentation for your church management software to ensure seamless integration.",
    },
    {
        question: "What kind of technical support is available for CristO?",
        answer: " Our team is available 24/7 to address your queries, troubleshoot issues, and provide guidance whenever you need it, ensuring you're never left without the help you require.",
    },
    {
        question: "Can CristO integrate with other church software or platforms we use?",
        answer: "Cristo can integrate with other church software and platforms through APIs, enabling seamless data exchange and communication. Additionally, it offers mobile app and web app functionalities, providing convenient access to church resources and services across various devices.",
    }
];
