import React, { useState } from 'react';
import './css/faq.css';

const MainFaq = () => {
    const [activeQuestion, setActiveQuestion] = useState(null);

    const toggleAccordion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };

    // Divide faqData into two arrays
    const halfIndex = Math.ceil(faqData.length / 2);
    const firstHalf = faqData.slice(0, halfIndex);
    const secondHalf = faqData.slice(halfIndex);

    return (
        <>
            <section>
                <div className="container mt-5">
                    <div className="row">
                    <h2 className="text-center text-black mb-5">Frequently Asked Questions (FAQ)</h2>
                        <div className="col-lg-6">
                            <div className="wrapper">
                                {firstHalf?.map((item, index) => (
                                    <div className="container" key={index} id='faq'>
                                        <div className={`question ${activeQuestion === index ? 'active' : ''}`} id='liststyle' onClick={() => toggleAccordion(index)}>
                                            {item?.question}
                                        </div>
                                        <div className="answercont" style={{ maxHeight: activeQuestion === index ? '1000px' : '0' }}>
                                            <div className="answer">
                                                <img src='assets/img/cristo/tick.png' alt="nodata" className="img-fluid" style={{ width: "20px", height: "20px", marginRight: "10px" }} />{item?.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="wrapper">
                                {secondHalf?.map((item, index) => (
                                    <div className="container" key={index + halfIndex} id='faq'>
                                        <div className={`question ${activeQuestion === index + halfIndex ? 'active' : ''}`} id='liststyle' onClick={() => toggleAccordion(index + halfIndex)}>
                                            {item?.question}
                                        </div>
                                        <div className="answercont" style={{ maxHeight: activeQuestion === index + halfIndex ? '1000px' : '0' }}>
                                            <div className="answer">
                                                <img src='assets/img/cristo/tick.png' alt="nodata" className="img-fluid" style={{ width: "20px", height: "20px", marginRight: "10px" }} />{item?.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MainFaq;

const faqData = [
    {
        id: 1,
        question: "What is CristO, and how can it benefit our church?",
        answer: "CristO (ChMS) is software tailored for churches and religious entities to simplify and coordinate diverse administrative responsibilities.",
    },
    {
        id: 2,
        question: "Is CristO compatible with our existing church management software?",
        answer: "CristO is compatible with most existing church management software systems. However, compatibility may vary depending on the specific features and integrations required. It's advisable to check with the CristO team or consult the documentation for your church management software to ensure seamless integration.",
    },
    {
        id: 3,
        question: "What kind of technical support is available for CristO?",
        answer: " Our team is available 24/7 to address your queries, troubleshoot issues, and provide guidance whenever you need it, ensuring you're never left without the help you require.",
    },
    {
        id: 4,
        question: "Can CristO integrate with other church software?",
        answer: "Cristo can integrate with other church software and platforms through APIs, enabling seamless data exchange and communication. Additionally, it offers mobile app and web app functionalities, providing convenient access to church resources and services across various devices.",
    },
    {
        id: 5,
        question: "What training options are available for our church members to learn how to use CristO effectively?",
        answer: "Cristo will provide online session training, direct training, and one-to-one communication as well.",
    },
    {
        id: 6,
        question: "Does CristO offer customizable features to suit our church's specific needs?",
        answer: "We offer a wide range of options to get started. Visit our website for more information or contact us directly for assistance.",
    },
    {
        id: 7,
        question: "How user-friendly is CristO for volunteers and staff with varying levels of tech experience?",
        answer: "Cristo is user-friendly, making it easy for your parish, province, or diocese with a comprehensive user manual.",
    },
    {
        id: 8,
        question: "How often is CristO updated with new features?",
        answer: "CristO updates its features based on user requests and feedback.",
    },
    {
        id: 9,
        question: "What is the cost of CristO?",
        answer: "Consult with the CristO team for more details.",
    },
    {
        id: 10,
        question: "What are all the sub products and features of CristO?",
        answer: "Parokia , Religio , Cathedra , Charisma",
    }
];
