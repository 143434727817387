import React from "react";

const Track = () => {
  return (
    <>
      {/* <div clasName="row "> */}
      <div
        id="w-node-_675c3d58-5f2a-1520-8f23-a35dad03b3db-c8061c97"
        className="inline-block text-center captraimg">
        <div className="flex-mc  mobile-vertical capetraimg">
          <a
            href="https://www.capterra.com/reviews/157753/CristO?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
            target="_blank"
            rel="noreferrer">
            {" "}
            <img
              className="capterra"
              src="https://assets.capterra.com/badge/949b7ca27302e5e753440de7cff4eab6.svg?v=2103755&p=157753"
              alt="capterra cristo"
              style={{ width: 150 }}
            />
          </a>
          <a
            href="https://www.softwareadvice.com/church/cristo-profile"
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: 33 }}>
            {" "}
            <img
              className="softwareadvice"
              src="https://badges.softwareadvice.com/reviews/39b1da38-a2a7-4988-bde8-a6e4006f6ab9"
              alt="capterra cristo"
              style={{ height: 52 }}
            />{" "}
          </a>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Track;
